/* Container to hold the button and separator */
.list-button-container {
    width: 100%;
  }
  
  /* Button styling */
  .list-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 12px 16px;
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #333;
    outline: none;
  }
  
  /* Left icon styling */
  .left-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  
  /* Text styling */
  .list-button-text {
    flex-grow: 1;
    text-align: left;
  }
  
  /* Chevron icon styling */
  .chevron-icon {
    width: 16px;
    height: 16px;
  }
  
  /* Separator styling */
  .button-separator {
    width: 100%;
    border: none;
    border-top: 1px solid #e0e0e0;
    margin: 0;
  }
  