html, body {
  overflow: hidden; /* Disable scrolling */
  height: 100%;     /* Ensure the height is 100% to fit the viewport */
  touch-action: pan-y; /* Allow vertical scrolling, disable horizontal scrolling and gestures */
  overscroll-behavior: none; /* The rubber band effect when scrolling past the ponit on iOS */
}

* {
  -webkit-touch-callout: none; /* Disable callout, like iOS long-press */
  -webkit-user-select: none;   /* Disable text selection */
  user-select: none;
}

body {
  margin: 0;  /* Remove default margins */
  padding: 0 0 48px 0; /* Remove default padding */
}

/* Hide scrollbar for Firefox */
.body {
  scrollbar-width: thin; /* Make the scrollbar thin */
  scrollbar-color: transparent transparent; /* Set scrollbar color to transparent */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.body::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

#root {
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack children vertically */
  min-height: 100vh; /* Minimum height of viewport */
}

.content {
  flex: 1; /* Allow the content to grow */
  overflow-y: auto; /* Enable scrolling for this section */
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



