/* src/components/components/AddItem.css */
.add-item {
    display: inline-flex;
    align-items: center;
    justify-content: left;
    padding: 0 16px 0 4px;
    height: 32px; /* Total height of the component */
    width: 100%; /* Occupy the full width of the container */
    color: var(--colorbrand-dark);
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-sizing: border-box;
    border-radius: 16px; /* Rounded edges */
    margin: 8px 0;
  }
  
  /* Hover effect */
  .add-item:hover {
    background-color: var(--colorbrand-lightdarker);
  }
  
  /* Plus icon circle */
  .add-item-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: var(--colorbrand-light);
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .add-item-icon img {
    width: 16px;
    height: 16px;
  }
  
  /* Text label */
  .add-item-text {
    font-size: 16px;
    font-weight: bold;
  }
  