/* Container for each checkbox and label */
.checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 14px;
    cursor: pointer;
    user-select: none;
  }
  
  /* Hide default checkbox */
  .checkbox-container input {
    display: none;
  }
  
  /* Custom checkbox appearance */
  .custom-checkbox {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 2px solid var(--colorborder-primary); /* Unchecked state */
    background-color: white;
    border-radius: 4px;
    margin-right: 8px;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    box-sizing: border-box;
  }
  
  /* Checked state with primary brand color */
  .custom-checkbox img {
    width: 12px;
    height: 12px;
  }
  
  .checkbox-container input:checked + .custom-checkbox {
    background-color: var(--colorbrand-dark); /* Checked background color */
    border-color: var(--colorbrand-dark);
  }
  
  /* Text label for checkbox */
  .checkbox-label {
    font-size: 14px;
    /* font-family: 'Lobster'; */
    color: var(colortext-primary);
  }
  
  .checkbox-list {
    display: flex;
    flex-direction: column; /* Stack checkboxes vertically */
    align-items: flex-start; /* Align items to the left */
  }