/* src/components/user/Avatar.css */
.avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #f9f1e9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    color: #E68D35;
    overflow: hidden;
  }
  
  .avatar-initials {
    font-weight: bold;
  }
  
  .avatar-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  