/* src/pages/WeeklyEvents.css */
.page-content {
  padding: 80px 15px 110px; /* Add bottom padding to avoid cutting off content */
  background-color: var(--colorbackground-light);
  overflow-y: auto; /* Allow scrolling within the content area */
  height: 100vh; /* Adjust height to account for the padding */
  box-sizing: border-box; /* Include padding in height calculation */
}

/* Hide scrollbar for Firefox */
.page-content {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.page-content::-webkit-scrollbar {
  display: none;
}

.filter-tags {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100%;
  padding: 0;
  margin-bottom: 12px;
  box-sizing: border-box;
}