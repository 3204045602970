/* src/components/user/AvatarHeader.css */
.avatar-header {
    display: flex;
    align-items: center;
    padding: 20px;
    width: 100%;
  }
  
  .greeting {
    margin-left: 20px;
    font-size: 18px;
    color: var(--colortext-primary);
  }
  