/* src/pages/InitialSetup.css */
 .setuppages {
    align-items: center;
    justify-content: center
  }
  
  .pages-container {
    position: relative;
    overflow: hidden;
    width: 400%;
    height: 100vh; /* Use 100vh for full screen height */
    display: flex;
    transition: transform 0.5s ease; 
    background: linear-gradient(180deg, #E5F9FA 77%, #C6E2E3 100%);
  }
  
  .page {
    width: 100vw; /* Each page takes 100% of the viewport width */
    height: 100vh; /* Each page takes 100% of the viewport height */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: black;
    font-size: 24px;
    /* font-family: 'Lobster'; */
    font-style: bold;
    padding: 0 10px;
    margin-top: 40px;
    gap: 20px;
  }

  .page-install {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 16px;
  }

  .page-install p {
    margin-bottom: 16px;
  }
  
 .page .text-subheader {
    margin: 0px;
  } 
  
  .dots {
    position: absolute;  /* Position relative to the nearest positioned ancestor (which is .pwapages) */
    bottom: 20px;        /* Position 20px from the bottom of the container */
    left: 50%;           /* Move the left edge of the dots to the center of the container */
    transform: translateX(-50%);  /* Offset the dots back by 50% of their width, centering them */
    display: flex;       /* Arrange the dots in a row */
    gap: 12px;           /* Add space between each dot */
  }
  
  .dot {
    width: 16px;
    height: 16px;
    background-color: black;
    border-radius: 50%;
    opacity: 0.5;
    transition: opacity 0.3s;
  }
  
  .dot.active {
    opacity: 1;
  }
  
  
  