/* src/components/event-display/EventCard.css */
.event-card {
  display: grid; /* Use grid layout */
  grid-template-rows: auto 1fr; /* Two rows: one for announcement, one for content */
  grid-template-columns: 1fr; /* Single column */
  border-radius: 8px;
  padding: 0px;
  margin-bottom: 0px;
  position: relative;
  transition: box-shadow 0.3s ease;
  text-align: left;
  background-color: white;
  box-shadow: 0px 0px 2px 0px rgba(79, 94, 113, 0.08), 0px 2px 4px 0px rgba(79, 94, 113, 0.06), 0px 4px 8px 0px rgba(79, 94, 113, 0.08);
  cursor: pointer;
}

.event-card.cancelled {
  border-top: 2px solid red; /* Red border for cancelled events */
  background-color: rgba(255, 0, 0, 0.1); /* Lighter red for visibility */
}

.event-card.event {
  background-color: var(--colorbrand-light);
}

.announcement {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
  background-color: white; /* Light red background for the announcement */
  padding: 10px; /* Padding for the announcement */
  width: 100%; /* Full width */
  border-radius: 8px; /* Rounded corners for the announcement */
  margin-bottom: 0px; /* Spacing between announcement and other content */
  grid-column: 1 / -1; /* Make it span across all columns */
  overflow: hidden; /* Hide any overflowing text */
  text-overflow: ellipsis; /* Add the ellipsis (...) */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
}

.announcement p {
  width: 100%;
  overflow: hidden; /* Hide any overflowing text */
  text-overflow: ellipsis; /* Add the ellipsis (...) */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
}

.event-content {
  display: grid; /* Use grid layout for event content */
  grid-template-columns: 115px 1fr; /* Two columns: image on the left, details on the right */
  align-items: center; /* Center items vertically */
}

.event-image {
  height: 80px; /* Height set according to image ratio (width 120px) */
  width: 115px; /* Cropping the image just slightly (should be 120px) */
}

.event-image img {
  height: 80px; 
  width: 115px;
  border-radius: 8px;
}

.event-details {
  margin-left: 15px; /* Adjust margin as needed */
}

.event-time {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: var(--colorbrand-dark);
  margin: 4px 0;
}

.cancelled-text {
  color: red;
  font-weight: bold;
}

.event-name {
  font-size: 18px;
  margin: 4px 0;
  color: var(--colortext-primary);
}

.loading-icon {
  margin-left: 8px;
}

.event-location {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var( --colortext-tertiary);
  margin: 4px 0;

}

.icon-location {
  width: 16px;
  margin-right: 5px;
}

.icon-alert {
  position: absolute;
  top: 6px;
  right: 8px;
  width: 20px;
}

h3 {
  margin: 0; /* Remove margin for the heading */
}

.announcement p {
  margin: 0; /* Remove margin for the paragraph */
  font-size: 14px; /* Adjust font size as needed */
  color: #333; /* Text color */
}


.grouped-eventcards {
  display: flex;
  flex-direction: column; /* Ensure events are stacked vertically */
  gap: 10px; /* Set space between event cards */
  box-sizing: border-box;
  margin-bottom: 20px;
  text-align: left;
}




/* Different styling for placeholder / no events card */
.noevent h2 {
  color: var(--colortext-secondary);
}
/* .event-content.noevent {
  grid-template-columns: 90px 1fr; 
}

.event-image.noevent, .event-image.noevent img  {
  height: 60px;
  width: 90px;
} */