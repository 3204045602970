/* src/components/components/SelectionCard.css */
/* Container styles for layout */
.selection-container.single {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width:100%;
  }
  
  .selection-container.two-column {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    width:100%;
  }
  
  .selection-container.three-column {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  
  /* Individual selection card */
  .selection-card {
    cursor: pointer;
    background-color: rgba(21, 165, 156, 0.10);    /* --colorbrand-dark at 10% opacity */
    padding: 15px;
    border-radius: 8px;
    transition: background-color 0.2s ease, color 0.2s ease;
    text-align: left;
  }
  
  .selection-card.selected {
    background-color: var(--colorbrand-dark);
    color: var(--colortext-white);
  }
  
  .selection-card-text {
    margin: 0;
    font-size: 16px;
    color: var(--colortext-primary);
  }
  
  .selection-card.selected .selection-card-text {
    color: var(--colortext-white);
  }
  
  /* Center text for multi-column layouts */
  .selection-container.two-column .selection-card-text,
  .selection-container.three-column .selection-card-text {
    text-align: center;
  }