/* src/components/components/Input.css */
.input-wrapper {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid var(--colorborder-primary);
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
}

.input-wrapper.disabled {
  opacity: 0.7;  /* Reduce opacity to indicate disabled state */
  pointer-events: none;  /* Prevent interaction */
}

.input-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.input-field {
  flex-grow: 1;
  border: none;
  outline: none;
  font-family: Inter;
  font-size: 14px;
  background-color: transparent;
  color: var(--colortext-primary);
}

.input-field::placeholder {
  color: var(--colortext-tertiary);
}

textarea.input-field {
  resize: vertical;
  min-height: 80px;
}

/* Label positioning, no changes needed here */
.input-row-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.input-row {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.input-row-label {
  text-align: left;
  flex-shrink: 0;
  width: 100px;
  margin-right: 10px;
}

.input-row input {
  flex-grow: 1;
  width: 100%;
  min-width: 0;
  box-sizing: border-box;
}
