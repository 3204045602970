/* src/pages/InitiationScreen.css */
.initiation-screen-container /*, .pwa-page-container*/ {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #E5F9FA 77%, #C6E2E3 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 99999; /* Ensure it overlays other content */
  }

  .logo {
    transition: all 5s ease; /* Smooth transition */
  }
  
  .initiation-screen-container .logo {
    width: 60%;
    max-width: 250px;
    height: auto;
  }

  .pwa-page-container .logo {
    width: 20%;
    max-width: 100px;
    height: auto;
  }
  
  .main-title {
    color: var(--colortext-primary);
    margin: 50px 20px;
    text-align: center;
    font-family: 'Lobster', cursive;
    font-size: clamp(54px, 6.5vw, 64px); /* The text size will scale between xpx and ypx based on the viewport width */
    font-style: bold;
    line-height: normal;
  }
  