/* Base tag styles */
.tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    font-weight: bold;
    font-size: 16px;
    transition: background-color 0.3s ease, color 0.3s ease;
    cursor: default;
    box-sizing: border-box;
    white-space: nowrap; /* Prevents tag text from wrapping */

  }
  
  /* Large size (default) */
  .tag.large {
    padding: 8px 16px;
    height: 40px;
    margin: 4px 4px;
  }
  
  /* Medium size */
  .tag.medium {
    padding: 6px 12px;
    height: 32px;
    margin: 4px 2px;
  }
  
  /* Clickable state for tags with onClick behavior */
  .tag.clickable {
    cursor: pointer;
  }
  
  /* Primary variant */
  .tag-primary {
    background-color: var(--colorbrand-dark);
    color: white;
   /* border: 2px solid var(--colorbrand-dark); */
  }
  
  .tag-primary.clickable:hover {
    background-color: var(--colorbrand-darker);
  }
  
  /* Secondary variant */
  .tag-secondary {
    background-color: var(--colorbrand-light);
    color: var(--colorbrand-dark);
   /* border: 2px solid var(--colorbrand-dark); */
  }
  
  .tag-secondary.clickable:hover {
    background-color: var(--colorbrand-lightdarker);
  }
  
  /* Tag label */
  .tag-label {
    line-height: 1; /* Ensures proper alignment */
  }
  
  /* Dismiss button (right side) */
  .tag-dismiss {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    width: 16px;
    height: 16px;
  }
  
  .tag-dismiss img {
    width: 16px;
    height: 16px;
  }
  
  .tag-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1px;
  }