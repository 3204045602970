/* src/components/new-event/new-event.css*/
/* General styling for new event */

h2 {
    text-align: left;
    margin: 6px 0 6px 0;
}

.layout {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;  /* Allow items to wrap onto multiple lines */
    flex-direction: column;
    gap: 8px; /* Define consistent vertical spacing between inputs */
    width: 100%;        /* Allow the layout to take full width */
    max-width: 100%;    /* Prevent it from exceeding the screen width */
    box-sizing: border-box;
    text-align: left;
}

.time-inputs {
    display: flex;
    justify-content: flex-start; /* Align items to the start */
    gap: 8px; /* Space between the inputs */
    width: 100%;
}

.time-inputs .input-wrapper {
    flex: 1 1 0; /* Inputs grow to fill available space but won't shrink smaller than their content */
    min-width: 0; /* Allows the input to shrink properly if space is tight */
    margin: 0;
}

.time-inputs p {
    margin: auto 8px;
    font-size: 16px;
    white-space: nowrap; /* Ensure "to" doesn't wrap onto a new line */
}
