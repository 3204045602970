:root {
    --colorbrand-dark: #15A59C;
    --colorbrand-darker: #1C8881;
    --colorbrand-light: #E9F9F7;
    --colorbrand-lightdarker: #ccf3ee;
    --colortext-primary: #120D26;
    --colortext-secondary: #747688;
    --colortext-tertiary: #A9A9A9;
    --colortext-white: #ffffff;
    --colorbackground-light: #FBFBFB;
    --colorbackgroundwhite: #FFFFFF;
    --colorborder-primary: #E4DFDF;
    --colorborder-light: #E4DFDF;
    --colorred-dark: #B00E0E;
  }