/* src/components/event-display/EventCardLarge.css */
.event-card-large {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 16px;
    background-color: white;
    box-shadow: 0px 0px 2px rgba(79, 94, 113, 0.08), 0px 2px 4px rgba(79, 94, 113, 0.06), 0px 4px 8px rgba(79, 94, 113, 0.08);
    cursor: pointer;
    text-align: left;
    transition: box-shadow 0.3s ease;
  }
  
  .event-card-large.cancelled {
    border: 2px solid red;
    background-color: rgba(255, 0, 0, 0.1);
  }
  
  .announcement-large {
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 16px;
    text-align: center;
    font-weight: bold;
  }
  
  .event-content-large {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .event-name-large {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    color: var(--colortext-primary);
  }
  
  .event-dates-large {
    font-size: 16px;
    font-weight: 600;
    color: var(--colorbrand-dark);
  }
  
  .event-image-large {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .event-image-large img {
    max-width: 100%;
    max-height: 300px;
    border-radius: 8px;
    object-fit: cover;
  }
  
  .event-description-large {
    font-size: 14px;
    color: var(--colortext-secondary);
    line-height: 1.6;
  }
  
  .event-location-large {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: var(--colortext-tertiary);
  }
  
  