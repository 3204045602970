/* Footer always sticks to the bottom of the viewport */
.page-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--colorbackground-light);
    padding: 10px;
    z-index: 998;
    display: flex;
    justify-content: space-between; /* Spread buttons evenly */
    gap: 6px; /* Set a 6px gap between buttons */
    box-sizing: border-box; /* Include padding in height calculation */
  }
  
  /* Footer buttons should stretch to fill the available space */
  .footer-button {
    flex-grow: 1; /* Make buttons stretch equally to fill the space */
  }
  
/* Icon-only buttons should only take up as much space as needed */
.footer-button.icon-only {
  flex-grow: 0; /* Prevent stretching */
  flex-shrink: 0; /* Prevent shrinking */
  flex-basis: auto; /* Use the natural size of the button */
  width: auto; /* Ensure the div wraps only around the button size */
  padding: 0; /* Remove any extra padding that might cause expansion */
}
  