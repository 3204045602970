p, li, .link {
    font-family: 'Lobster';
    color: var(--colortext-primary);
    margin: 4px 0;
    -webkit-touch-callout: text; /* Disable callout, like iOS long-press */
    -webkit-user-select: text;   /* Disable text selection */
    user-select: text;
}

ul, li {
  text-align: left;
}

.styledText, .styledText p {
  font-family: 'Lobster';
}

.link {
  /* color: var(--colorbrand-dark); */
  color: #007bff; /* Change to your desired color */
    text-decoration: none; /* Remove underline */
    font-weight: bold; /* Optional: make the text bold */
}

a:visited {
  color: var(--colorbrand-dark);
}

.textBlock {
  text-align: left;
}

.text-subheader {
    font-size: 32px;
    margin: 10px 0;
  }

.primary-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 20px;
    font-family: 'Lobster';
    font-style: bold;
    background-color: var(--colorbrand-dark) ;
    color: var(--colortext-white);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
  }
  
.primary-button:hover {
    background-color: var(--colorbrand-darker);
  }

.underline-link {
  text-decoration: underline;
  color: var(--colortext-primary); 
  cursor: pointer;
  margin: 12px 0;
}

.underline-link:hover {
  color: #000000; 
}

.spacer-medium {
  margin-bottom: 10px;
}

.spacer-large {
  margin-bottom: 20px;
}

.bottom-margin, p.bottom-margin {
  margin-bottom: 10px;
}

.top-margin {
  margin-top: 20px;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 8px;
  max-width: 100%;
}
