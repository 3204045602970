.upload-box {
    width: 360px;
    height: 240px;
    border: 2px dashed #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
  }
  
  .upload-placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .image-preview img {
    max-width: 100%;
    max-height: 100%;
  }
  
  .image-preview {
    position: relative;
  }
  
  .image-preview button {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  
  .branding-tab .radio-list {
  flex-direction: row;
  gap: 15px;
}