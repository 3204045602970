.create-event-page {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
  overflow: hidden; /* Prevent page scrolling, control scroll inside content */
  background-color: var(--colorbackground-light);
}

/* Header remains fixed */
.create-event-page header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

/* Top navigation stays fixed below the header */
.top-nav {
  position: fixed;
  top: 60px; /* Adjust based on header height */
  left: 0;
  width: 100%;
  background-color: var(--colorbackground-light);
  z-index: 999;
  padding: 10px 0;
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none; /* Hides scrollbar in Firefox */
  height: 40px; /* Set fixed height to allow me to set appropriate content padding */
}

.top-nav::-webkit-scrollbar {
  display: none; /* Hides scrollbar in Chrome/Safari/Edge */
}

/* Content area is scrollable */
.create-event-content {
  margin-top: 110px; /* Account for the height of the header and top nav */
  margin-bottom: 100px; /* Account for the height of footer */
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto; /* Enable vertical scrolling */
}