.upload-container {
    box-sizing: border-box;
    width: 100%;
    padding: 16px;
    border: 2px dashed var(--colorborder-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    aspect-ratio: 3 / 2; /* Maintain a 3:2 aspect ratio */
    max-height: 400px;
  }
  
  .upload-placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: var(--colortext-secondary);
  }
  
  .upload-placeholder img {
    width: 40px;
    margin-bottom: 8px;
  }
  
  .image-preview {
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Crop and center image */
  }
  
  .remove-button {
    margin-top: 10px;
    background-color: #f44336;
    color: white;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
  }
  
  .remove-button:hover {
    background-color: #d32f2f;
  }
  