/* Base styles for the segmented switch */
.segmented-switch {
    display: flex;
    width: 100%; /* Stretch the segmented switch to the full container width */
    border-radius: 24px;
    overflow: hidden;
   /* border: 2px solid var(--colorbrand-dark); */
    box-sizing: border-box;
    background-color: var(--colorbrand-light);
    /* margin: 4px 4px; */
  }

  .segmented-switch.disabled {
    opacity: 0.7;  /* Reduce opacity to indicate disabled state */
    pointer-events: none;  /* Prevent interaction */
  }
  
  /* Large size */
  .segmented-switch.large .switch-item {
    padding: 8px 16px; /* 40px height */
    font-size: 16px;
  }
  
  /* Medium size */
  .segmented-switch.medium .switch-item {
    font-size: 16px; /* 32px height */
    height: 32px;
  }
  
  /* Each switch item will stretch equally */
  .switch-item {
    flex-grow: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    color: var(--colorbrand-dark);
    background-color: var(--colorbrand-light);
    border-radius: 24px;
 /*   border-right: 2px solid var(--colorbrand-dark); /* Divider between items */
  }
  
  /* Remove the border for the last item 
  .switch-item:last-child {
    border-right: none;
  }*/
  
  /* Selected state for the switch */
  .switch-item.selected {
    background-color: var(--colorbrand-dark);
    color: white;
 /*   border-color: var(--colorbrand-dark); */
  }

  .switch-item.selected:hover {
    background-color: var(--colorbrand-darker);
  }
  
  /* Hover effect on non-selected items */
  .switch-item:not(.selected):hover {
    background-color: var(--colorbrand-lightdarker);
  }
  