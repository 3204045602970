.Container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    /* Hide scrollbar for Firefox */
    scrollbar-width: thin; /* Make the scrollbar thin */
    scrollbar-color: transparent transparent; /* Set scrollbar color to transparent */
}
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  .Container::-webkit-scrollbar {
    display: none; /* Hide scrollbar */
  }