.subheader {
  position: relative; /* Ensures it stays within the flow of the container */
  width: 100%; /* Full width of the parent container */
  padding: 8px;
  box-sizing: border-box; /* Includes padding in width/height calculations */
}

.subheader-content {
  display: flex; /* Flexbox to control layout of the content */
  justify-content: space-between; /* Space between title and optional content */
  align-items: center; /* Vertically center the items */
}

.section-title {
  margin: 0; /* Remove margin to avoid extra space */
  flex-grow: 1; /* Allow title to take up available space */
  text-align: left; /* Align text to the left */
  word-wrap: break-word; /* Wrap long text if necessary */
  white-space: normal; /* Allow wrapping onto multiple lines */
}

.header-extra {
  margin-top: 0; /* Remove top margin for better vertical alignment */
  margin-left: auto; /* Push extra content (e.g., options button) to the right */
  display: flex; /* Ensure any extra content is flex-aligned */
  align-items: center; /* Vertically center additional content */
}
