/* src/components/new-event/children/Timeslot.css */
.timeslot-card {
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 8px;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;        /* Ensure it takes full width of its container */
    max-width: 100%;    /* Prevents overflow on smaller screens */
    overflow: hidden;   /* Ensures content does not overflow */
  }
  
  .timeslot-card .time-inputs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0;
  }
  


  
