  
  /* Base button styles */
  .btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease, color 0.3s ease;
    border: 2px solid transparent;
    width: 100%; /* Stretch button to full width */
    box-sizing: border-box;
  }
  
  /* Primary button styles */
  .btn-primary {
    background-color: var(--colorbrand-dark);
    color: white;
    border-color: var(--colorbrand-dark);
  }
  
  .btn-primary:hover {
    background-color: var(--colorbrand-darker);
  }

  /* Secondary button styles (inverted colors) */
  .btn-secondary {
    background-color: white;
    color: var(--colorbrand-dark);
    border-color: var(--colorbrand-dark);
  }

  .btn-secondary:hover {
    background-color: #f0f0f0;
  }
  
  /* Button with icon */
  .button-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px; /* Spacing between icon and label */
    width: 20px;
    height: 20px;
  }
  
  .button-label {
    text-align: center;
  }
  
  /* Ensure that icon-only buttons maintain proper size */
  .btn-icon-only {
    width: auto; /* Icon-only buttons will not stretch */
    padding: 10px;
  }

  .btn-icon-only .button-icon {
    margin-right: 0px;
  }
  
  /* Disabled state */
  .btn:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
  
  /* 'grey' can be set as a className in component props */
  .btn.grey {
    background-color: #747688;
    color: white;
    border-color: #747688;
  }
  .btn.grey:hover {
    background-color: #575866;
  }

  /* 'red' can be set as a className in component props */
  .btn.red {
    background-color: red;
    color: white;
    border-color: red;
  }
  .btn.red:hover {
    background-color: rgb(210, 0, 0);
  }