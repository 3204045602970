.header {
    position: fixed;
    box-sizing: border-box;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white; /* Background color for the header */
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Add a shadow to distinguish the header */
    z-index: 999; /* Ensure the header is on top */
  }
  
  .header-content {
    display: flex;
    align-items: center;
  }
  
  .page-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
    flex-grow: 1;
    text-align: left;
  }
  
  .header-extra {
    margin-top: 10px;
    /* Add any additional styling for optional content */
  }

  .back-button {
    background: none;
    border: none;
    padding: 0;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .back-button img {
    width: 24px;
    height: 24px;
  }