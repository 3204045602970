.event-series {
    display: flex;
    overflow-x: auto;
    padding: 8px
  }
  
  .event-series-scroll {
    display: flex;
    gap: 8px;
  }
  
  .event-card .exceptionIcon {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 20px;
    height: 20px;
  }
  
  .event-card-date {
    font-size: 0.875rem;
    font-weight: bold;
    text-align: center;
  }

  .event-card-occurrence {
    padding: 4px;
    width: 70px;
  }
  
  .textCancelled {
    text-decoration: line-through;
    color: var(--colortext-secondary);;
  }