/* Container for each radio button and label */
.radio-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 14px;
    cursor: pointer;
    user-select: none;
  }
  
  /* Hide default radio button */
  .radio-container input {
    display: none;
  }
  
  /* Custom radio button appearance */
  .custom-radio {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 2px solid var(--colorborder-primary); /* Unchecked state */
    border-radius: 50%;
    margin-right: 8px;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    box-sizing: border-box;
    background-color: white;
  }
  
  .inner-circle {
    width: 12px;
    height: 12px;
    background-color: var(--colorbrand-dark); /* Checked color */
    border-radius: 50%;
  }
  
  /* Selected radio button with primary brand color */
  .radio-container input:checked + .custom-radio {
    border-color: var(--colorbrand-dark);
  }
  
  /* Text label for radio button */
  .radio-label {
    font-size: 14px;
    /* font-family: 'Lobster'; */
    color: var(colortext-primary);
  }
  
  .radio-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  