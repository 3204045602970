/* src/components/BottomNav.css */
.bottom-nav {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    background-color: #fff;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    border-top: 1px solid #ddd;
    padding: 0;
  }
  
  .nav-item {
    border-radius: 40px;
  }

  .nav-icon {
    width: 52px;
    height: auto;
  }
  
  
  .bottom-nav a.active .nav-icon {
    filter: invert(90%) sepia(90%) saturate(2000%) hue-rotate(150deg);
  }
  
  
  