/* src/components/components/SlideUpModal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9001;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  pointer-events: auto; /* Ensure the overlay intercepts clicks */
}

.slide-up-modal {
  position: fixed;
  width: 100%;
  max-height: 90vh; /* Prevent modal from exceeding page height */
  background-color: white;
  border-radius: 15px 15px 0 0;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Enable scrolling for content overflow */
  animation: slideUp 0.3s ease;
  z-index: 9002; /* Higher than overlay to ensure modal sits above */
  pointer-events: auto; /* Allow interactions within the modal */
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #ddd;
}

.modal-header h2 {
  margin: 0;
  font-size: 18px;
}

.close-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-content {
  display: flex;
  height: fit-content;
  flex-direction: column; /* Stack items vertically */
  gap: 10px; /* Add space between items */
  padding: 15px;
  overflow-y: auto;
  align-items: flex-start; /* Align content to the left */
}

.modal-content p{
  margin: 0;
}

.modal-footer {
  padding: 15px;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: center;
}

