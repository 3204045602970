/* src/pages/ViewEvent.css */
.view-event-container {
    padding-bottom: 20px;
    overflow: hidden;
  }

  .announcement-container {
   /* border-top: 2px solid red; /* Red border for cancelled events */
    background-color: rgba(255, 0, 0, 0.1) !important;
  }
  
  .event-header {
    width: 100%; /* Full width of the screen */
    position: absolute;
    top: 0;
    z-index: 100;
  }

  .separator {
    margin: 0 0.3em; /* Adjust the spacing as needed - used in date formatting*/
  }

  /* Back Button CSS */
  .floatingButton .btn {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 800;
    background-color: rgba(0, 0, 0, 0.20);;  /* Transparent */
    border: none; /* Remove borders */
  }
  .floatingButton .button-icon img {
    width: 32px; /* Size of the back icon */
    height: 32px;
  }

  .event-image-container {
    width: 100%;
    max-width: 500px;
    height: auto;
    position: relative;
    aspect-ratio: 3 / 2;
    margin: 0 auto; /* Centre image */
    z-index: 50;
    overflow: hidden;
  }
  
  .event-header .event-image {
    width: 100%;
    height: 100%;
    object-fit: contain; /* This ensures the image covers the entire container without stretching */
  }

  .scrollable-page {
    overflow-y: auto;
    position: relative;
    height: 100vh;
  }

  /* Spacer under image to allow page to scroll without moving image */
  .header-spacer {
    width: 100%;
    max-width: 500px;
    height: auto;
    position: relative;
    aspect-ratio: 3 / 2;
    z-index: 1;
    overflow: hidden;
  }
  
  .event-details-container {
    background-color: white;
    position: sticky;
    top: 0;
    border-radius: 20px;
    padding: 20px 0;
    margin-top: -30px; /* Overlap the image by 30px */
    overflow: hidden;
    height: calc(100vh - 30px);
    z-index: 200;
    display: flex;
  flex-direction: column;
  }
  
  .event-details-container h1 {
    margin-top: 0;
  }
  
  .event-details-container p, .event-details-container ul {
    margin: 10px 0;
  }

  /* Event metadata and tabs fixed position */
  .event-details-header {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px; /* Space between items */
    padding: 0 10px;
  }

  .event-details-header p,  .event-details-header h1 {
    margin: 0;
  }

  .event-details-header .top-nav {
  position: relative;
  top: 0;
  overflow-x: auto;
  padding-top: 0;
  background-color: var(--colorbackground-white); 
 /* background-color: var(--colorbackground-light); */
}

.viewevent-time {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  color: var(--colorbrand-dark);
}

.viewevent-location {
  display: inline-flex;
  align-items: center; /* Aligns the image and text vertically */
  justify-content: center;
  gap: 8px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  color: var(--colortext-primary);
  padding: auto;
}

.viewevent-location img {
  margin: auto 8px auto 0; 
  vertical-align: middle; /* Align the image with the middle of the text */
}


/* Event content displays tab data */
.viewevent-content {
  overflow: auto;
  flex-grow: 1;
  padding: 0 10px 60px 10px;
}

.viewevent-container {
  padding: 10px;
  border-radius: 10px;
  background-color: var(--colorbackground-light);
  margin-bottom: 6px;
  overflow: hidden;
}

.viewevent-container h3 {
  margin: 0 0 8px 0;
}


/* -------- Agenda ---------- */

.agenda-levels {
  display: flex;
  flex-wrap: wrap; /* Allows levels to wrap onto multiple lines */
  gap: 4px; /* Space between each level */
  margin-top: 6px;
}

.agenda-levels .level {
  color: var(--colortext-tertiary); 
  font-size: 14px;
  padding: 2px 8px;
}

.agenda-overview {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Align the text to the top */
  gap: 10px;
  margin: 0;
  font-size: 18px;
}

.agenda-overview h3 {
  margin: 0;
  font-size: 18px;
  flex-grow: 1; /* Allow h3 to take up the remaining space */
  white-space: normal; /* Allow h3 to wrap text */
}

.agenda-overview p {
  margin: 0;
  font-size: 18px;
  text-align: right;
  flex-shrink: 0; /* Prevent p from shrinking */
  white-space: nowrap; /* Ensure the time doesn't wrap */
}


/* -------- Pricing ---------- */

.viewevent-container h3 {
  position: sticky;
  left: 0;
}

/* Pricing Section Container */
.viewevent-container.price {
  margin-bottom: 20px;
  max-width: 100%;
  box-sizing: border-box;
  overflow-x: auto; /* Enable horizontal scrolling if content overflows */
}

/* Price Table */
.price-table {
  width: 100%; /* Ensures the table fills the container */
  min-width: max-content; /* Prevent table from shrinking too small */
  border-collapse: collapse;
}

/* Table Header Row */
.price-table-header {
  display: flex;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}

/* Table Rows */
.price-table-row {
  display: flex;
  border-bottom: 1px solid #ddd;
}

.price-item-name-header, .price-item-name {
  width: 110px;
  padding: 10px;
  font-weight: bold;
  position: sticky;
  left: 0;
  background-color: var(--colorbackground-light);
  z-index: 20;  /* First column remains fixed on scroll */
}

.price-ticket-type-header, .price-ticket-type {
  flex-grow: 1;
  padding: 10px;
  text-align: center;
}

/* Ensure horizontal scrolling only affects the table, not the entire page */
.price-ticket-type-header, .price-ticket-type {
  min-width: 100px; /* Prevent cells from shrinking too small */
}


/* ------------ Location ----------- */

.viewevent-container.address p {
  margin: 0;
}

.viewevent-container.address {
  display: flex;
  flex-wrap: wrap; /* Allows the button to move under the address if needed */
  gap: 10px; /* Space between address and button */
}

.address-info {
  flex-grow: 1; /* Address takes up the space it needs */
}

.maps-button-container {
  display: flex;
  align-items: flex-end;
}

.maps-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 4px; /* Adjust space between icon and text */
  text-decoration: none;
  background-color: var(--colorbackground-light);
  padding: 10px;
  border-radius: 8px;
  transition: background-color 0.2s;
  width: 80px; /* Adjust button width as needed */
}

.maps-button:hover {
  background-color: var(--colorbackground-dark);
}

.maps-button img {
  width: 24px;
  height: 24px;
}

.maps-button span {
  font-size: 14px;
  font-weight: 600;
  color: var(--colortext-primary);
  white-space: normal; /* Allow text to wrap */
}