/* Ensure the nav container spans the full width of the screen */
.top-nav {
    display: flex;
    overflow-x: auto; /* Enables horizontal scrolling */
    white-space: nowrap; /* Prevents the content from wrapping */
    background-color: var(--colorbrand-light);
    padding: 10px 0;
    scrollbar-width: none; /* Hides scrollbar for Firefox */
  }
  
  .top-nav::-webkit-scrollbar {
    display: none; /* Hides scrollbar for Chrome, Safari, and Edge */
  }
  
  /* Style for each tab button */
  .top-nav button {
    background: none; /* Remove default button background */
    border: none; /* Remove default button border */
    font-size: 16px;
    text-transform: uppercase;
    color: var(--colortext-secondary);
    padding: 10px 10px;
    cursor: pointer;
    white-space: nowrap; /* Prevents text wrapping */
    flex-shrink: 0; /* Prevents buttons from shrinking when container is smaller */
  }
  
  /* Style for active tab */
  .top-nav .active-tab {
    color: var(--colorbrand-dark); /* Apply brand dark color for active tab */
    border-bottom: 2px solid var(--colorbrand-dark); /* Adds bottom border to active tab */
  }
  